<template>
  <div class="grid surveyform-wrap">
    <div class="col-12">
      <div class="card">
        <ProgressSpinner v-if="false === ready && false == sent" style="width: 50px; height: 50px" stroke-width="8" fill="#EEEEEE" />
        <label v-if="true === ready && true == sent"
          >Thank you for your response. Please view your response by clicking <a class="link" @click="viewResponseForm">link</a></label
        >
        <div v-if="true === ready && false == sent" class="survey-content" style="width: 100%">
          <h2>{{ survey.name }}</h2>
          <div class="p-fluid formgrid grid">
            <div v-for="group of survey.groups" :key="group" class="group-wrap field col-12" v-show="currentGroup == group.index">
              <h4>{{ parseProfile(group.name) }}</h4>
              <div v-for="question of group.questions" :key="question" class="field col-12">
                <label v-if="question.appear">{{ parseProfile(question.name) }}</label>

                <!-- Short Question -->
                <InputText
                  v-if="question.type == 'Short Question' && question.appear"
                  v-model="question.response"
                  :type="question.answer.inputType"
                  :class="{
                    invalid: (question.response == null || question.response?.length == 0 || paramInvalid.includes(question.id)) && submit,
                  }"
                  @change="checkChange(question)"
                />

                <!-- Long Question -->

                <Textarea
                  v-if="question.type == 'Long Question' && question.appear"
                  v-model="question.response"
                  :type="question.answer.inputType"
                  rows="4"
                  :class="{
                    invalid: (question.response == null || question.response?.length == 0 || paramInvalid.includes(question.id)) && submit,
                  }"
                  @change="checkChange(question)"
                />

                <!-- Single Choice Question -->
                <div v-if="question.type == 'Single Choice Question' && question.appear">
                  <div v-for="choice of question.answer.options" :key="choice" class="field-radiobutton col-6">
                    <RadioButton
                      v-model="question.response"
                      :value="choice.choice"
                      :class="{
                        invalid: question.response == null && submit,
                      }"
                      @change="checkChange(question)"
                    />
                    <label>{{ choice.choice }}</label>
                  </div>
                </div>

                <!-- Multiple Choice Question -->
                <div v-if="question.type == 'Multiple Choice Question' && question.appear">
                  <MultiSelect
                    v-model="question.response"
                    :options="question.answer.options"
                    placeholder="Select Choice"
                    :class="{
                      invalid: question.response == null && submit,
                    }"
                    @change="checkChange(question)"
                  />
                </div>
                <div v-if="(question.response == null || question.response?.length == 0) && submit && question.appear">
                  <small class="p-invalid-label">This field is required.</small>
                </div>
                <div v-if="paramInvalid.includes(question.id)">
                  <small class="p-invalid-label">The input answer is out of range</small>
                </div>
              </div>
            </div>
            <div class="group-nav">
              <Button label="Prev" icon="pi pi-angle-left" iconPos="left" class="p-button-rounded p-button-outlined col-6" @click="groupNav(-1)" />
              <Button label="Next" icon="pi pi-angle-right" iconPos="right" class="p-button-rounded p-button-outlined col-6" @click="groupNav(1)" />
            </div>
          </div>
        </div>
        <div v-if="true === ready && false == sent" class="footer">
          <Button label="Submit" icon="pi pi-check" class="p-button-text" @click="submitResponse" />
        </div>
      </div>
    </div>
  </div>
</template>

//
<script>
import surveyService from "../../service/survey";
import respondentService from "../../service/respondent";

export default {
  data() {
    return {
      surveyid: this.$route.params.surveyid,
      responseid: null,
      survey: {},
      ready: false,
      answer: null,
      currentGroup: 0,
      validate: {},
      sent: false,
      submit: false,
      paramInvalid: [],
      profile: {},
    };
  },

  mounted() {
    this.getSurvey();
    this.paramInvalid = [];
    console.log(this.$router);
  },

  methods: {
    parseProfile(msg) {
      let parsed = msg;
      let keys = parsed.match(/{{(.+?)}}/g);
      // console.log(keys);
      for (let i in keys) {
        // console.log(keys[i], this.profile[keys[i].replace("{{", "").replace("}}", "")]);
        parsed = parsed.replace(keys[i], this.profile[keys[i].replace("{{", "").replace("}}", "")]);
      }
      // console.log(parsed);

      return parsed;
    },
    getSurvey() {
      this.submit = false;
      let obj = { surveyid: this.surveyid };
      //get survey
      surveyService.getSurvey(obj).then((result) => {
        this.survey = { id: result[0].id, name: result[0].name };
        this.survey.groups = [];

        result[0].groups.forEach((group) => {
          let obj = { id: group.id, name: group.name, questions: [], sequence: group.sequence };
          this.survey.groups.push(obj);
        });

        result[0].questions.forEach((question) => {
          // include attribute variable in profile object
          if (question.key == "profile") {
            this.profile[question.answer.attribute.variable] = 0;
          }

          // reset the format for multiple choice question, easier for multiselect
          if (question.type == "Multiple Choice Question") {
            let choices = [];
            question.answer.options.forEach((option) => {
              choices.push(option.choice);
            });
            question.answer.options = choices;
          }

          // handle the question appear condition
          // add an "appear" variable to control whether the question should show or not
          // in this case i suppose there is a OR relationship between multiple conditions
          question.appear = true;
          if (question.condition != null) {
            question.condition.forEach((condition) => {
              if (this.profile[condition.variable] <= parseInt(condition.value)) {
                question.appear = false;
              }
            });
          }

          // set response to store user ans
          question.response = null;
          //push question to corresponding group in this.survey
          this.survey.groups.forEach((group) => {
            if (group.id == question.group) {
              group.questions.push(question);
            }
          });
        });

        // sort the sequence of group
        this.survey.groups.sort((a, b) => {
          return a.sequence - b.sequence;
        });

        // sort the sequence of question
        this.survey.groups.forEach((group) => {
          group.questions.sort((a, b) => {
            return a.sequence - b.sequence;
          });
        });
        // assign index
        let qIndex = 0;
        let gIndex = 0;
        this.survey.groups.forEach((group) => {
          group.index = gIndex++;
          group.questions.forEach((q) => {
            q.index = qIndex++;
          });
        });
        console.log(this.survey);
        this.ready = true;
      });
    },
    groupNav(step) {
      this.currentGroup += step;
      if (this.currentGroup < 0) {
        this.currentGroup = 0;
      }
      if (this.currentGroup > this.survey.groups.length - 1) {
        this.currentGroup = this.survey.groups.length - 1;
      }
    },

    calculateProfile(question) {
      console.log(question);
      // check if the question contains attribute
      if (question.key == "profile") {
        // get the attirbute variable
        let variable = question.answer.attribute.variable;
        // reset the variable store as 0 to recalculate it again
        this.profile[variable] = 0;
        this.survey.groups.forEach((group) => {
          group.questions.forEach((q) => {
            if (q.key == "profile") {
              // get the question having the same vairable, and add score to this.profile
              if (q.answer.attribute.variable == variable) {
                if (q.type == "Multiple Choice Question") {
                  let score = 0;
                  q.response.forEach((response) => {
                    q.answer.attribute.input.forEach((inputans) => {
                      if (response == inputans.key) {
                        score += parseInt(inputans.score);
                      }
                    });
                  });
                  this.profile[variable] += score;
                } else {
                  q.answer.attribute.input.forEach((inputans) => {
                    if (inputans.key == q.response) {
                      this.profile[variable] += parseInt(inputans.score);
                    }
                  });
                }
              }
            }
          });
        });
      }
      console.log(this.profile);
    },

    updateQuestionAppear(question) {
      // First: go to some question directly based on the current ans
      if ("condition" in question.answer) {
        let targetQ;
        question.answer.condition.forEach((condition) => {
          if (condition.key == question.response) {
            targetQ = condition.question;
          }
        });

        this.survey.groups.forEach((group) => {
          if (group.id == question.group) {
            group.questions.forEach((q) => {
              q.appear = false;
              if (q.sequence <= question.sequence || q.sequence >= targetQ.sequence) {
                q.appear = true;
              }
              //clear response
              if (!q.appear) {
                q.response = null;
              }
            });
          }
        });
      }

      // Second: question.appear amendment based on profile values
      this.survey.groups.forEach((group) => {
        group.questions.forEach((q) => {
          if (q["condition"] != null) {
            q.appear = true;
            q.condition.forEach((condition) => {
              if (this.profile[condition.variable] <= parseInt(condition.value)) {
                q.appear = false;
              }
            });

            //clear response
            if (!q.appear) {
              q.response = null;
            }
          }
        });
      });
    },

    checkChange(question) {
      this.calculateProfile(question);
      this.updateQuestionAppear(question);
    },

    validateResponse() {
      let passValidate = true;
      this.survey.groups.forEach((group) => {
        group.questions.forEach((question) => {
          // only consider the questions that appear
          if (question.appear) {
            if (question.response == null) {
              passValidate = false;
            } else if (question.type == "Long Question" || question.type == "Short Question") {
              if (question.response.length == 0) {
                passValidate = false;
              }
              // check parameter
              if ("params" in question.answer) {
                question.answer.params.forEach((params) => {
                  if (params.key == "max") {
                    if (question.response > parseInt(params.value)) {
                      console.log(question.response, params.value);
                      passValidate = false;
                      this.paramInvalid.push(question.id);
                    }
                  }
                  if (params.key == "min") {
                    if (question.response < parseInt(params.value)) {
                      console.log(question.response, params.value);

                      passValidate = false;
                      this.paramInvalid.push(question.id);
                    }
                  }
                });
              }
            }
          }
        });
      });
      console.log(passValidate);
      return passValidate;
    },

    submitResponse() {
      console.log(this.survey);
      this.submit = true;
      this.paramInvalid = [];
      let wholeProfile = this.profile;
      if (!this.validateResponse()) {
        return;
      } else {
        this.ready = false;
        let answer = [];

        this.survey.groups.forEach((group) => {
          group.questions.forEach((question) => {
            let obj = {
              question: question.id,
              group: question.group,
              answer: question.response,
            };
            answer.push(obj);
          });
        });

        let obj = { survey: this.surveyid, answer: answer, profile: wholeProfile };
        respondentService.postRespondent(obj).then((result) => {
          this.responseid = result.id;
          this.sent = true;
          this.ready = true;
        });
      }
    },

    viewResponseForm() {
      this.$router.replace("/response/" + this.responseid);
    },
  },
};
</script>

//
<style scoped lang="scss">
.surveyform-wrap {
  .col-12 {
    ::v-deep(.card) {
      display: flex;
      align-items: center;
      flex-direction: column;
      min-height: 35rem;
      justify-content: center;
      .link {
        cursor: pointer;
      }
      .survey-content {
        width: 100% !important;
        .group-wrap {
          min-height: 15rem;
        }
        .field {
          .p-invalid-label {
            color: red;
          }

          .p-inputtextarea,
          .p-multiselect,
          .p-inputtext {
            &.invalid {
              border-color: red;
            }
          }

          .field-radiobutton {
            .p-radiobutton {
              &.invalid {
                .p-radiobutton-box {
                  border-color: red;
                }
              }
            }
          }
        }
        .group-nav {
          display: grid;
          grid-template-columns: 50% 50%;
          column-gap: 1rem;
          margin: 0 auto;
          .p-button {
            width: 10rem;
            padding: 0.5rem;
          }
        }
      }
    }
  }
}
</style>
